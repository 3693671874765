<template>
  <div class="login-container">
    <el-container>
      <el-header class="login-header">
        <div class="login-header-container">
          <div class="login-logo">
            <img
              class="login-img"
              src="../assets/images/login/logo.png"
              alt=""
            />
          </div>
          <div class="login-title">
            <img
              class="title-img"
              src="../assets/images/login/platform.png"
              alt=""
            />
          </div>
        </div>
      </el-header>

      <el-main>
        <div class="login-main">
          <div class="login-body-container">
            <div class="login-box">
              <div class="login-box-welcome-tip">欢迎登录仲裁查询平台</div>
              <div class="box-login-btn">
                <div
                  :class="{
                    'login-btn-item': activeIndex != index,
                    'login-btn-item active': activeIndex == index,
                  }"
                  v-for="(item, index) in btnData"
                  :key="index"
                  @click="taggleBtn(index)"
                >
                  {{ item.title }}
                </div>
                <div class="respondent-login-btn">被申请调解人登录</div>
              </div>
              <div class="login-body-form">
                <!-- 申请人登录 -->
                <el-form
                  class="el-form-one"
                  :model="ruleForm"
                  status-icon
                  :rules="rules"
                  ref="FormOne"
                  v-show="activeIndex == 0"
                >
                  <div class="form-lable">
                    <img
                      src="../assets/images/login/phone.svg"
                      class="form-icon"
                    />
                    <span class="from-title">操作员手机号</span>
                  </div>
                  <el-form-item prop="phone">
                    <el-input
                      type="number"
                      v-model="ruleForm.phone"
                      placeholder="请输入操作员手机号"
                      @input="checkLength"
                      @focus="isfocusBorder(1)"
                      @blur="isblurBorder(1)"
                      :class="{ 'el-form-borderShow': borderOneShow == true }"
                    ></el-input>
                  </el-form-item>
                  <div class="form-lable">
                    <img
                      src="../assets/images/login/password.svg"
                      class="form-icon"
                    />
                    <span class="from-title">密码</span>
                  </div>
                  <el-form-item prop="pass">
                    <el-input
                      :type="pwd"
                      v-model="ruleForm.pass"
                      placeholder="请输入密码"
                      @input="checkpassLength"
                      @focus="isfocusBorder(2)"
                      @blur="isblurBorder(2)"
                      :class="{ 'el-form-borderShow': borderTwoShow == true }"
                    >
                      <i
                        slot="prefix"
                        style="display: flex; align-items: center"
                      >
                        <img
                          :src="eyeIcon"
                          v-if="isShoweye == true"
                          @click="changePwdShow"
                          class="pass-eye"
                        />
                      </i>
                    </el-input>
                  </el-form-item>

                  <el-form-item>
                    <el-button
                      :class="{
                        'login-btn-isLoginBtn': isLoginBtn == true,
                        'login-btn': isLoginBtn == false,
                      }"
                      type="primary"
                      @click="submitForm('FormOne')"
                      >登录</el-button
                    >
                  </el-form-item>

                  <div class="login-form-buttom">
                    <!-- <div class="forget-password">忘记密码</div> -->
                    <div class="off-register" @click="goRegister">马上注册</div>
                  </div>
                </el-form>
                <!-- 被申请调解人登录 -->
                <el-form
                  class="el-form-Two"
                  :model="ruleForm"
                  status-icon
                  :rules="rulesTwo"
                  ref="FormTwo"
                  v-show="activeIndex == 1"
                >
                  <div class="form-lable">
                    <img
                      src="../assets/images/login/idcard.svg"
                      class="form-icon"
                    />
                    <span class="from-title">证件号</span>
                  </div>
                  <el-form-item prop="certificate">
                    <el-input
                      type="text"
                      v-model="ruleForm.certificate"
                      placeholder="请输入证件号"
                      @input="checkcertificateLength"
                      @focus="isfocusBorder(1)"
                      @blur="isblurBorder(1)"
                      :class="{ 'el-form-borderShow': borderOneShow == true }"
                    ></el-input>
                  </el-form-item>
                  <div class="form-lable">
                    <img
                      src="../assets/images/login/check.svg"
                      class="form-icon"
                    />
                    <span class="from-title">查验码</span>
                  </div>
                  <el-form-item prop="checkcode">
                    <el-input
                      type="text"
                      v-model="ruleForm.checkcode"
                      placeholder="请输入查验码"
                      @input="checkcodeLength"
                      @focus="isfocusBorder(2)"
                      @blur="isblurBorder(2)"
                      :class="{ 'el-form-borderShow': borderTwoShow == true }"
                    >
                    </el-input>
                  </el-form-item>

                  <el-form-item>
                    <el-button
                      :class="{
                        'login-btn-isLoginBtn': isLoginBtnTwo == true,
                        'login-btn': isLoginBtnTwo == false,
                      }"
                      type="primary"
                      @click="submitForm('FormTwo')"
                      >登录</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div class="login-welcome-tip">欢迎登录仲裁查询平台</div>
          </div>
        </div>
      </el-main>

      <el-footer class="login-footer">
        <div class="login-footer-container">
          <div class="login-footer-address">
            地址：江西省景德镇市珠山区昌江大道瓷都晚报5楼
          </div>
          <div class="login-footer-tip">
            Copyright&copy;2021-2021 景德镇仲裁委 版权所有
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import check from "../utils/check.js";

export default {
  name: "Login",
  data() {
    let validPhone = (rule, value, callback) => {
      if (this.activeIndex == 0 && !value) {
        callback(new Error("手机号码不能为空"));
      } else if (this.activeIndex == 0 && !check.isvalidPhone(value)) {
        callback(new Error("请输入正确手机号码"));
      } else {
        callback();
      }
    };
    let validPass = (rule, value, callback) => {
      if (this.activeIndex == 0 && !value) {
        callback(new Error("密码不能为空"));
      } else {
        callback();
      }
    };
    let validCertificate = (rule, value, callback) => {
      if (this.activeIndex == 1 && !value) {
        callback(new Error("证件号不能为空"));
      } else {
        callback();
      }
    };
    let validCheckcode = (rule, value, callback) => {
      if (this.activeIndex == 1 && !value) {
        callback(new Error("查验码不能为空"));
      } else {
        callback();
      }
    };
    return {
      activeIndex: 1,
      pwd: "password", // 密码输入是否脱敏
      borderOneShow: false, // 输入框获得焦点时父元素下边框高亮
      borderTwoShow: false,
      isShoweye: false, // 显示眼睛
      isLoginBtn: false, // 登陆按钮高亮显示
      isLoginBtnTwo: false, // 登陆按钮高亮显示
      eyeIcon: require("../assets/images/login/eye-c.svg"),
      screenWidth: document.body.clientWidth,

      btnData: [
        {
          title: "申请调解人登录",
        },
        {
          title: "被申请调解人登录",
        },
      ],

      ruleForm: {
        phone: "", // 手机号
        pass: "", // 密码
      },
      rules: {
        phone: [{ validator: validPhone, trigger: "blur", required: true }],
        pass: [{ validator: validPass, trigger: "blur", required: true }],
      },
      rulesTwo: {
        certificate: [
          { validator: validCertificate, trigger: "blur", required: true },
        ],
        checkcode: [
          { validator: validCheckcode, trigger: "blur", required: true },
        ],
      },
    };
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
    
    this.activeIndex = 1
    if(this.screenWidth < 549){
      this.activeIndex = 1;
    } 
    // else {
    //     this.activeIndex = 0;
    //   }
  },
  watch: {
    /* 监听*/
    screenWidth(val) {
      this.screenWidth = val;
      if (this.screenWidth < 549) {
        this.activeIndex = 1;
      } 
      // else {
      //   this.activeIndex = 0;
      // }
    },
  },

  methods: {
    // 申请人被申请人登录切换
    taggleBtn(index) {
      this.activeIndex = index;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (formName == "FormOne") {
            let phone = this.ruleForm.phone;
            let pass = this.ruleForm.pass;
            this.$axios
              .post(`/web/web/proposer/doLogin?phone=${phone}&password=${pass}`)
              .then((res) => {
                // console.log(res);
                if (res && res.data.code == 0 && res.status == 200) {
                  this.$message({
                    message: "登陆成功",
                    type: "success",
                  });
                  sessionStorage.setItem("username", res.data.data);
                  sessionStorage.setItem("password", pass);
                  this.$router.push("/index");
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                  });
                }
              })
              .catch((err) => {
                this.$message({
                  message: "请求异常，服务器出错",
                });
              });
          } else {
            // console.log(2);
            let certificate = this.ruleForm.certificate;
            let checkcode = this.ruleForm.checkcode;
            this.$axios.post(`/web/web/respondent/doLogin?num=${certificate}&code=${checkcode}`).then(res => {
              // console.log(res);
              if(res.data.code == 0 && res.status == 200){
                this.$message({
                  message: "登陆成功",
                  type: "success",
                });
                sessionStorage.setItem("respondentname", certificate);
                this.$router.push({name: 'Index2'});
              }else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                  });
                }
            }).catch(err => {
              this.$message({
                  message: "请求异常，服务器出错",
                });
            })
          }
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    // 手机号
    checkLength(e) {
      if (e.length > 11) {
        this.ruleForm.phone = e.slice(0, 11);
      }

      if (this.ruleForm.phone != "" && this.ruleForm.pass != "") {
        this.isLoginBtn = true;
      } else {
        this.isLoginBtn = false;
      }
    },
    // 密码
    checkpassLength(e) {
      if (e.length > 0) {
        this.isShoweye = true;
      } else {
        this.isShoweye = false;
      }
      if (this.ruleForm.phone != "" && this.ruleForm.pass != "") {
        this.isLoginBtn = true;
      } else {
        this.isLoginBtn = false;
      }
    },
    // 证件号
    checkcertificateLength() {
      if (this.ruleForm.certificate != "" && this.ruleForm.checkcode != "") {
        this.isLoginBtnTwo = true;
      } else {
        this.isLoginBtnTwo = false;
      }
    },

    // 查验码
    checkcodeLength() {
      if (this.ruleForm.certificate != "" && this.ruleForm.checkcode != "") {
        this.isLoginBtnTwo = true;
      } else {
        this.isLoginBtnTwo = false;
      }
    },
    // 密码脱敏显示or不显示
    changePwdShow() {
      if (this.pwd == "password") {
        this.pwd = "text";
        this.eyeIcon = require("../assets/images/login/eye-o.svg");
      } else {
        this.pwd = "password";
        this.eyeIcon = require("../assets/images/login/eye-c.svg");
      }
    },
    // 获得焦点父元素下边框高亮
    isfocusBorder(e) {
      if (e == 1) {
        this.borderOneShow = true;
      }
      if (e == 2) {
        this.borderTwoShow = true;
      }
    },
    // 失去焦点父元素下边框高亮
    isblurBorder(e) {
      if (e == 1) {
        this.borderOneShow = false;
      }
      if (e == 2) {
        this.borderTwoShow = false;
      }
    },
    // 马上注册
    goRegister() {
      this.$router.push("/register");
    },
  },
};
</script>
<style lang="scss">
@import "../assets/css/login.scss";
</style>